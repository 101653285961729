/* ... existing styles ... */

.highlighted-row {
  background-color: rgba(0, 128, 0, 0.1); /* Light green background */
  font-weight: bold;
}

.highlighted-row:hover {
  background-color: rgba(0, 128, 0, 0.2); /* Darker green on hover */
}
