* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Newsreader:wght@400;700&display=swap');

.job-detail {
  position: relative;  /* This is needed for absolute positioning of child elements */
  font-family: 'JetBrains Mono', monospace;
  padding: 80px;
  padding-top: 80px;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}

.job-detail-title {
  font-family: 'Newsreader';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;
  text-align: left;
  align-items: center;
  letter-spacing: -0.02em;
  text-align: left;
  max-width: 800px;
  color: #FFE6D9;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 60px; /* 80px space after title */
}

.job-detail-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem; /* Reduce bottom margin */
  margin-bottom: 60px;
}

.job-detail-table tr {
  border-bottom: 1px solid #333;
}

.job-detail-table tr:last-child {
  border-bottom: none;

}

.job-detail-label {
    /* Company: */

    width: 360px;
    height: 18px;

    font-family: 'JetBrains Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */

    color: #FFE6D9;

    opacity: 0.5;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.job-detail-value {
    /* Mayo Clinic */

    width: 420px;
    height: 42px;

    font-family: 'JetBrains Mono', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height */

    color: #FFE6D9;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 1;

}

.job-detail-description h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  padding-top: 60px;
}

.job-detail-description {
  margin-top: 1.2rem; /* Add a small top margin */
  font-family: 'JetBrains Mono', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 150% */

  color: #FFE6D9;


  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;

}

.job-detail-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.back-button, .apply-button {
  position: fixed;
  top: 20px;
  background: rgba(255, 230, 217, 0.1);
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #FFE6D9;
  font-family: 'JetBrains Mono', monospace;
  border-radius: 0px;
  text-decoration: none; /* Ensures no underline by default */
  transition: background-color 0s, color 0s; /* Smooth transition for hover effect */
}

.back-button {
  left: 20px;
}

.apply-button {
  right: 20px;
}

.back-button:hover, .apply-button:hover {
  background-color: #FFE6D9;
  color: #1E1E1E;
  text-decoration: none; /* Explicitly removes underline on hover */
}

.job-description-list {
  list-style-type: none;
  padding: 1em 1em 1em 2.5em;  /* Increased left padding to account for bullets */
  margin-bottom: 1em;
  border: 1px solid #333;  /* Add this line for the border */
  border-radius: 0px;  /* Optional: adds slightly rounded corners */
}

.job-description-list-item {
  position: relative;
  margin-bottom: 0.5em;
  padding-left: 1em;
}

.job-description-list-item::before {
  content: '•';
  position: absolute;
  left: -1.1em;  /* Adjusted to account for increased padding */
  color: #FFE6D9;
}

.job-description-header {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

.job-description-paragraph {
  font-family: 'JetBrains Mono', monospace; /* or your chosen monospace font */
  font-size: 16px; /* Adjust as needed */
  line-height: 1.5; /* This is 150% of the font size */
  margin-bottom: 1em;
}

.job-details-container {
  background-color: #1a1a1a;
  color: #e0e0e0;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.job-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}


.job-title {
  font-size: 2.5rem;
  color: #f8e1d2;
  text-align: center;
  margin: 0;
}

.job-meta {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.meta-item {
  display: flex;
  justify-content: space-between;
}

.meta-label {
  color: #888;
}

.job-description {
  line-height: 1.6;
  margin-top: 20px;
}

.job-detail-subsection-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  color: #FFE6D9; /* Match this with your color scheme */
}

.job-detail-list {
  list-style-type: disc;
  padding-left: 2em;
  margin-bottom: 1em;
}

.job-detail-list-item {
  margin-bottom: 0.5em;
}

.job-detail-paragraph {
  margin-bottom: 1em;
}

.job-detail-section-title {
  margin-top: 0; /* Remove top margin from the "Job Description" heading */
  margin-bottom: 1rem; /* Add some bottom margin */
}

.job-description-paragraph {
  margin-bottom: 15px;
  line-height: 1.8;
}

.job-description-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 2.5em;
  margin-bottom: 1em;
  color: #FFE6D9; /* Adjust this color to match your design */
}

/* Add more specific styles as needed */

/* Add this media query at the end of the file */
@media screen and (max-width: 768px) {
  .job-detail {
    padding: 20px;
  }

  .job-detail-header {
    position: relative;  /* Add this */
    margin-bottom: 20px;
    padding-top: 80px;
  }

  .back-button, .apply-button {
    position: absolute;  /* Change to absolute positioning */
    top: 0;
    padding: 10px 15px;  /* Adjust padding as needed */
  }

  .back-button {
    left: 20px;
    top: 20px;
    font-size: 14px;
  }

  .apply-button {
    right: 20px;
    top: 20px;
    font-size: 14px;
  }

  .job-detail-title {
    margin-top: 100px;  /* Adjust this value to ensure enough space for buttons */
    font-size: 40px;
    line-height: 44px;
  }
}

