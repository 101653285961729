.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

/* Dark theme */
.App.dark {
  background-color: #1E1E1E;
  color: #FFE6D9;
  --bg-color: #1E1E1E;
  --text-color: #FFE6D9;
  --border-color: #333333;
  --hover-bg-color: #2c2c2c;
  --alternate-row-bg-color: #252525;
  /* other dark theme variables */
}

/* Light theme */
.App.light {
  background-color: #ffffff;
  color: #333333;
  --bg-color: #ffffff;
  --text-color: #333333;
  --border-color: #e0e0e0;
  --hover-bg-color: #f5f5f5;
  --alternate-row-bg-color: #f9f9f9;
  /* other light theme variables */
}

/* Blue theme */
.App.blue {
  background-color: #0077BE;
  color: #FFFFFF;
  --bg-color: #0077BE;
  --text-color: #FFFFFF;
  --border-color: #ffffff;
  --hover-bg-color: #0088d1;
  --alternate-row-bg-color: #0066a3;
  /* other blue theme variables */
}

/* Ensure the header and other elements also change with the theme */
.App.light header,
.App.light .job-listings,
.App.light .filters select,
.App.light .filters input[type="text"] {
  background-color: #FFFFFF;
  color: #1E1E1E;
}

.App.light .job-item:nth-child(even) {
  background-color: #f0f0f0;
}

/* Adjust styles for blue theme if needed */
.App.blue header,
.App.blue .job-listings,
.App.blue .filters select,
.App.blue .filters input[type="text"] {
  background-color: #425D64; /* This might also need to be changed */
  color: #FFFFFF;
}

.App.blue .job-item:nth-child(even) {
  background-color: #ffffff08; /* This is for alternating row colors */
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #1a1a1a;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

/* Your other original styles */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}
