@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');

:root {
  --bg-color: #1E1E1E;
  --text-color: #FFE6D9;
  --input-bg: rgba(242, 242, 242, 0.1);
  --even-row-bg: #252525;
}

.App.light {
  --bg-color: #FFFFFF;
  --text-color: #1E1E1E;
  --input-bg: rgba(0, 0, 0, 0.1);
  --even-row-bg: #f0f0f0;
}

.App.blue {
  --bg-color: #0077BE;
  --text-color: #FFFFFF;
  --input-bg: rgba(255, 255, 255, 0.2);
  --even-row-bg: #0077BE;
}

body, .job-listings {
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'JetBrains Mono', monospace;
  margin: 0;
  padding: 0;
  font-weight: 400;
  box-sizing: border-box;
  letter-spacing: -0.02em; /* Adjust this value as needed */
}

.job-listings {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Use the full viewport height */
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

/* Ensure the header is part of the normal flow */
.header {
  position: relative;
  width: 100%;
  background-color: var(--bg-color);
  padding: 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.cube-container {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 40px;
  top: 20px;
  z-index: 1000;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .header {
    display: flex;
    justify-content: flex-start; /* Align items to the start */
    align-items: center;
    padding: 10px 20px; /* Add some padding */
  }

  .cube-container {
    position: relative;
    top: auto;
    right: auto;
    margin: 20px auto;
  }

  .mobile-title {
    margin-left: 10px; /* Add some space between cube and title */
  }
}

/* Desktop styles for cube positioning */
@media screen and (min-width: 769px) {
  .cube-container {
    position: fixed !important;
    right: 40px !important;
    top: -10px !important;
    width: 50px;
    height: 50px;
    z-index: 1000;
  }

  .mobile-title {
    display: none;
  }

  /* Adjust the tooltip position for desktop */
  .cube-tooltip {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 37px;
    margin-right: -60px;
    font-size: 14px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    padding: 5px 10px;
    z-index: 1;
    background-color: transparent !important;
  }

  .cube-container:hover .cube-tooltip {
    opacity: 1;
  }

  .cube-container {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 40px;
    top: 20px;
    z-index: 1000;
    cursor: pointer;
  }

  /* Rest of your existing styles */
}

/* Ensure the header can accommodate the fixed positioning */
@media screen and (min-width: 769px) {
  .header {
    position: relative;
    padding-right: 60px; /* Make room for the cube */
  }
}

/* Ensure the cube itself is centered within its container */
.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mobile-title {
  position: relative;
  font-size: 0.8rem;
  margin: 0;
  color: #FFE6D9;
  z-index: 1001;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.filters-button {
  margin-left: auto; /* This will push the button to the right */
}


.filters-button:hover {
  background-color: #FFE6D9;
  color: #1E1E1E;
  text-decoration: none; /* Explicitly removes underline on hover */
}

@media (max-width: 768px) {
  .header {
    justify-content: space-between;
    padding: 0px;
  }

  .cube-container {
    margin-right: 0px;
  }

  .mobile-title {
    font-size: 0.7rem;
  }

  .filters-button {
    padding: 10px 15px;
    font-size: 12px;
  }
}

h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

.job-table-container {
  flex: 1;

  padding-bottom: 70px; /* Increase this value */
  position: relative;
  padding: 0 0 70px 0; /* Add bottom padding, keep horizontal padding at 0 */
  display: block; /* Change this from flex to block */
  width: 100%;
  overflow-x: auto;
}

/* Add a new class for the loader container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* This ensures it takes full height of its parent */
}

.job-table {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr; /* Adjust based on your column count */
  width: 100%;
  border-collapse: collapse;
}

.job-table-header,
.job-table-row {
  display: contents;
}

.job-table-cell {
  padding: 10px;
  border-bottom: 1px solid #444;
  display: flex;
  align-items: center;
}

.job-table-header .job-table-cell {
  font-weight: bold;
  background-color: var(--bg-color);
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Media query for mobile viewports */
@media screen and (max-width: 767px) {
  .job-table {
    display: block;
  }

  .job-table-row {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
    border-bottom: 1px solid #444;
  }

  .job-table-header {
    display: none;
  }

  .job-table-cell {
    padding: 5px 10px;
    border-bottom: none;
  }

  .job-table-cell:first-child {
    font-weight: bold;
    font-size: 16px;
  }

  .job-table-cell:nth-child(2) {
    color: #8F827C;
  }
}

.job-table th {
  padding: 10px 20px; /* Add horizontal padding to header cells */
  text-align: left;
  border-bottom: 0px solid var(--border-color);
  background-color: var(--bg-color);
  font-size: 14px;
  cursor: pointer;
}

.job-table th::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--bg-color);
  z-index: -1;
}

.job-table td {
  padding: 10px 20px;
  border-bottom: 1px solid var(--border-color);
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

/* Adjust column widths */
.job-table {
  table-layout: fixed;
}

.job-table .job-title {
  width: 40%;
}

.job-table .company,
.job-table .location,
.job-table .salary-range {
  width: 20%; /* Increased to distribute the space from the removed Apply column */
}

/* Remove any styles specific to the Apply column */
.job-table .apply {
  display: none; /* Hide any remaining Apply column elements */
}

/* Ensure content doesn't wrap and shows ellipsis */
.job-table td {
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  padding: 10px 20px;
}

.job-table tr {
  border-bottom: 1px solid var(--border-color);
}

/* Add this to ensure the first and last columns align with the container edge */
.job-table th:first-child,
.job-table td:first-child {
  padding-left: 20px;
  min-width: 460px;
  max-width: 460px; /* Add this line */
  width: 460px; /* Add this line */
}

.job-table th:last-child,
.job-table td:last-child {
  padding-right: 20px;
}

.job-table th {
  text-align: left;
  padding: 10px 20px;
  color: #8F827C;
  text-transform: uppercase;
  font-weight: normal;
}

.job-table th.apply {
  text-align: center;
}

.job-table td {
  padding: 10px 20px;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.job-table td.apply {
  text-align: center;
  padding: 10px 0; /* Remove horizontal padding for the Apply column */
}

.job-table th button {
  background: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 8px;
  margin: -8px;
  color: #8F827C;
  text-transform: uppercase;
}

.job-table th button:hover {
  background-color: rgba(255, 230, 217, 0.05);
  color: white; 
}

.job-table th button:disabled {
  cursor: default;
  opacity: 1; /* Keeps the text fully opaque */
  /* Add any other styles to match the enabled buttons */
}

/* Add this new rule for job title links */
.job-table td a {
  color: var(--text-color);
  text-decoration: none;
}

/* Modify the existing hover style */
.job-table tbody tr:hover {
  background-color:#FFE6D9; /* Slight overlay */
  cursor: pointer;
}

.job-table tbody tr:hover td,
.job-table tbody tr:hover td a {
  color:  #252525;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-bottom: 70px; /* Ensure there's space above the status bar */
}

.load-more-button {
  padding: 16px 16px;
  background-color: #383B3D;
  color: #FFE6D9;
  border: 1px solid #383B3D;
  border-radius: 0px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  transition: all 0.3s ease;
}

.load-more-button:not(:disabled) {
  background-color: #FFE6D9;
  color: #1E1E1E;
  border-color: #FFE6D9;
}

.load-more-button:not(:disabled):hover {
  background-color: #FFD1B8;
  border-color: #FFD1B8;
}

.load-more-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.load-more-button .animated-ellipsis {
  margin-left: 4px;
}

.loading-spinner {
  margin-left: 10px;
}

.job-count {
  font-size: 14px;
  color: #FFE6D9;
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center; /* Align items vertically */
  height: 100%; /* Take full height of the status bar */
}

.loading-text {
  font-style: normal;
  color: #8F827C;
  margin-left: 8px;
  display: inline-flex; /* Use inline-flex to align the ellipsis */
  align-items: center;
}

.animated-ellipsis {
  display: inline-flex;
  align-items: center;
}

.animated-ellipsis span {
  opacity: 0;
  animation: ellipsis 1s infinite;
  display: inline-block;
  margin-left: 2px;
}

.animated-ellipsis span:nth-child(1) {
  animation-delay: 0s;
}

.animated-ellipsis span:nth-child(2) {
  animation-delay: 0.5s;
}

.animated-ellipsis span:nth-child(3) {
  animation-delay: 1s;
}

@keyframes ellipsis {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* If you want different styles for light and dark themes */
.light .job-count {
  color: #666;
}

.dark .job-count {
  color: #aaa;
}

.light .loading-text {
  color: #999;
}

.dark .loading-text {
  color: #777;
}

.filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px 20px 20px; 
  width: calc(100% - 220px); /* 200px for space + 20px for right padding */
  max-width: 1200px; /* Adjust this value as needed */
  box-sizing: border-box;
  gap: 8px; /* Increased gap between filters */
}

.filter-dropdown {
  flex: 1;
  min-width: 150px; /* Increased minimum width */
  max-width: calc(25% - 12px); /* Ensure each dropdown takes up to 25% of the space minus some gap */
}

.filter-select {
  width: 100%;
  padding: 10px;
  background-color: var(--input-bg);
  color: var(--text-color);
  border: none;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px; /* Ensure font size is not too large */
}

/* Adjust the remote toggle to fit inline */
.remote-toggle {
  display: block !important;
  align-items: center;
  white-space: nowrap;
  margin-left: 8px;
  padding-top: 10px;
}

@media (max-width: 1200px) {
  .filters {
    width: calc(100% - 20px);
    flex-wrap: wrap;
  }

  .filter-dropdown {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}

@media (max-width: 768px) {
  .job-listings {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cube-container {

    display: flex;
    margin-bottom: 20px;
  }

  .filters-button {
    margin: 10px;
    padding: 10px;
    background-color: var(--input-bg);
    color: var(--text-color);
    border: none;
    font-family: 'JetBrains Mono', monospace;
    font-size: 14px;
    cursor: pointer;
  }

  .filters {
    display: none;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 0 10px;
    gap: 8px; /* Add this line to control space between filters */
  }

  .filters.show {
    display: flex;
  }

  .filter-dropdown,
  .filter-select,
  .remote-toggle {
    width: 100%;
    margin-bottom: 0; /* Remove bottom margin */
  }

  .filter-select {
    height: 40px;
  }

  .remote-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px; /* Add a small top margin to separate it from other filters */
  }

  .job-listings {
    padding: 20px;  /* Adjust this value as needed */
  }

  .filters {
    padding: 0;  /* Remove any existing padding */
    margin: 20px 0;  /* Add vertical margin, keep horizontal at 0 */
  }

  .filters-button {
    position: absolute;
    top: 20px;  /* Match the top padding of job-listings */
    right: 0px;  /* Match the right padding of job-listings */
  }

  .filter-dropdown,
  .remote-toggle {
    margin-bottom: 10px;  /* Add space between filter elements */
  }

  /* Ensure the last filter element doesn't have extra bottom margin */
  .remote-toggle {
    margin-bottom: 0;
  }

  /* Adjust the cube and title positioning if necessary */
  .cube-container,
  .mobile-title {
    margin-left: 0;
  }

  .filters {
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
  }

  .filter-dropdown,
  .filter-select,
  .remote-toggle {
    width: 100%;
    max-width: none;
  }

  .job-table td {
    white-space: normal;
    word-break: break-word;
  }

  body {
    overflow-x: visible;
  }

  .job-listings {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .scrollable-content {
    flex: 1;
    width: 100%;

    -webkit-overflow-scrolling: touch;
  }

  .header,
  .status-bar {
    position: static;
  }
}

.filters select {
  width: 100%;
  padding: 10px;
  background-color: var(--input-bg);
  color: var(--text-color);
  border: none;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23949493'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-color: rgba(255, 230, 217, 0.05);
  padding-right: 30px; /* Ensure text doesn't overlap the arrow */
}

/* Remove default arrow in IE */
.filters select::-ms-expand {
  display: none;
}

.filters select.placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.filters select:not(.placeholder) {
  color: var(--text-color);
}

.filters select option {
  color: var(--text-color);
}

.filters input[type="text"] {
  flex-grow: 1;
  min-width: 240px;
}

.filters input[type="text"]::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.filters select:hover {
  cursor: pointer;
  background-color: rgba(255, 230, 217, 0.1);  /* Slightly lighter on hover */
  border-color: rgba(255, 255, 255, 0.5);  /* More visible border on hover */
}

.job-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.job-item {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr;
  gap: 16px;
  height: 40px;
  align-items: center;
  padding: 0 20px;
}

.job-item:nth-child(even) {
  background-color: var(--even-row-bg);
}

.job-item p {
  margin: 0;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.job-title {
  margin-left: 20px;
}

.apply-button-container {
  position: relative;
}

.apply-button {
  display: inline-block;
  padding: 5px 0px;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.more-options-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0 5px;
}

.apply-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 5px 0;
  z-index: 1000;
}

.apply-options a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
}

.apply-options a:hover {
  background-color: #f0f0f0;
}

.apply-button:hover {
  text-decoration: underline;
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 280px; /* Adjust as needed */
}

.select-selected {
  background-color: var(--input-bg);
  color: var(--text-color);
  padding: 0 12px;
  height: 42px;
  line-height: 42px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.select-selected:hover {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.5);
}

.select-selected::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--text-color);
}

.select-items {
  position: absolute;
  background-color: var(--bg-color);
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.select-items div {
  color: var(--text-color);
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.select-items div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.status-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-color);
  border-top: 1px solid var(--border-color);
  z-index: 1000;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.status-bar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative; /* Add this */
}

.status-bar-center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.loading-indicator {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.job-count {
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
}

.loading-text {
  margin-left: 10px;
  color: var(--text-color);
  opacity: 0.7;
}

.animated-ellipsis {
  display: inline-flex;
  width: 12px;
}

.animated-ellipsis span {
  opacity: 0;
  animation: ellipsis 1.2s infinite;
  margin-left: 2px;
}

.animated-ellipsis span:nth-child(1) { animation-delay: 0s; }
.animated-ellipsis span:nth-child(2) { animation-delay: 0.2s; }
.animated-ellipsis span:nth-child(3) { animation-delay: 0.4s; }

@keyframes ellipsis {
  0% { opacity: 0; }
  20% { opacity: 1; }
  100% { opacity: 0; }
}

.status-bar-right {
  display: flex;
  align-items: center;
}

.load-more-button {
  padding: 8px 16px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--text-color);
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
}

@media (max-width: 768px) {
  .status-bar {
    padding: 0 20px;
  }

  .job-count {
    font-size: 12px;
  }

  .loading-text {
    font-size: 12px;
  }

  .load-more-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

/* Ensure horizontal layout on all screen sizes */
@media (max-width: 768px) {
  .status-bar-content {
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
  }

  .job-count {
    margin-right: 10px;
  }

  .load-more-button {
    padding: 6px 12px;
    font-size: 12px;
  }
}

/* Adjust the job table container to account for the fixed status bar */
.job-table-container {
  padding-bottom: 60px; /* Adjust this value based on the height of your status bar */
}

/* If you want different styles for light and dark themes */
.light .status-bar {
  background-color: #f8f9fa;
  border-top-color: #dee2e6;
}

.dark .status-bar {
  background-color: #292828;
  border-top-color: #4F4F4F;
}

/* Add these styles for the toggle switch */
.remote-toggle {
  display: block;
  align-items: center;
  padding-left: 4px;
  height: 42px;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-right: 8px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 230, 217, 0.1);
  transition: .4s;
  border-radius: 24px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--text-color);
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #FFE6D9;
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
  background-color: var(--bg-color);
}

.toggle-label {
  font-size: 14px;
  transition: color 0.3s ease;
}

.toggle-label.off {
  color: #8F827C; /* Grey color for off state */
}

.toggle-label.on {
  color: var(--text-color); /* Default text color when on */
}

.filter-select {
  /* Existing styles */
  color: rgba(255, 230, 217, 0.5); /* This sets the default color, which will be used for the placeholder */
}

.filter-select option {
  color: var(--text-color); /* This sets the color for actual options */
}

/* This targets the select when a real value is chosen */
.filter-select:not(:invalid) {
  color: var(--text-color);
}

/* For browsers that support it, we can style the placeholder directly */
.filter-select option[value=""] {
  color: rgba(255, 230, 217, 0.5);
}

/* Existing styles for cube container */
.cube-container {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 40px;
  top: -10px;
  z-index: 1000;
}

/* Existing styles for cube */
.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Adjust the tooltip position for desktop */
.cube-tooltip {
  position: absolute;
  right: 60px; /* Adjust this value to move left/right */
  top: 50px; /* Adjust this value to move up/down */
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

/* Existing hover effect */
.cube-container:hover .cube-tooltip {
  opacity: 1;
}

/* Mobile styles */
@media (max-width: 768px) {
  .cube-container {
    position: relative;
    top: auto;
    right: auto;
    margin: 20px auto;
  }

  /* Hide tooltip on mobile */
  .cube-tooltip {
    display: none;
  }
}

/* Existing styles for cube container */
.cube-container {
  width: 50px;
  height: 50px;
  position: fixed; /* Change from relative to fixed */
  top: 20px; /* Distance from the top of the viewport */
  right: 20px; /* Distance from the right of the viewport */
  z-index: 1000; /* Ensure it's above other elements */
}

/* Ensure the cube itself is centered within its container */
.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Add a media query for mobile devices */
@media (max-width: 768px) {
  .cube-container {
    position: relative; /* Change back to relative for mobile */
    top: auto;
    right: auto;
    margin: 20px auto; /* Center the cube on mobile */
  }
}

/* Existing styles for cube */
.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: spin 10s infinite linear;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    position: relative;
  }

  .cube-container {
    width: 50px;
    height: 50px;
  }

  .mobile-title {
    position: relative ;
    font-size: 0.8rem;
    margin: 0;
    color: #FFE6D9;
    z-index: 1001;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .mobile-title.hidden {
    opacity: 0;
    transform: translateY(-20px);
  }

  .filters-button {
    position: absolute;
    right: 0px;
    top: 20px;
    transform: translateY(-50%);
    padding: 10px 15px;
    background-color: #353331;
    color: var(--text-color);
    border: none;
    font-family: 'JetBrains Mono', monospace;
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    width: auto;
    min-width: 0;
    z-index: 1001;
    
  }

  /* Ensure the job listings don't overlap with the header */
  .job-table-container {
    margin-top: 32px;
    padding: 0 0px;
  }
}

/* Adjust the animation to rotate around the center */
@keyframes spin {
  from { transform: rotateX(0deg) rotateY(0deg); }
  to { transform: rotateX(360deg) rotateY(360deg); }
}

/* Ensure content doesn't overflow on all screen sizes */
.job-listings {
  max-width: 100vw;
  overflow-x: visible;
}

/* Adjust main container padding */
.job-content {
  padding: 0 0px;
}

.job-count {
  /* Keep your existing styles */
  display: flex;
  align-items: center;
}

.loading-text {
  margin-left: 8px;
  color: #8F827C;
}

.load-more-button {
  background-color: #1E1E1E;
  border: none;
  color: #1E1E1E;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  padding: 0;
  margin-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.load-more-button:hover {
  text-decoration: underline;
}

.animated-ellipsis {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

/* Dark theme */
.dark .status-bar .load-more-button {
  background-color: #3E3B3A !important;
  color: #FFE6D9 !important;

}

.dark .status-bar .load-more-button:hover {
  background-color: #FFE6D9 !important;
  color: #1E1E1E !important;
  text-decoration: none !important;
}

.job-content {
  flex: 1; /* Take up remaining space */
  display: block; /* Change this from flex to block */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.logo-link {
  display: block;
  width: 100%;
  height: 100%;
}

.logo-and-filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.company-logo {
  width: 50px; /* Adjust this value to your preferred size */
  height: auto; /* Maintains aspect ratio */
  margin-top: 10px;
  margin-left: 20px; /* Adds space between logo and filters */
}

.filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 20px 20px 20px; 
  width: calc(100% - 220px); /* 200px for space + 20px for right padding */
  max-width: 1200px; /* Adjust this value as needed */
  box-sizing: border-box;
  gap: 8px; /* Increased gap between filters */
}

.filter-dropdown {
  flex: 1;
  min-width: 150px; /* Increased minimum width */
  max-width: calc(25% - 12px); /* Ensure each dropdown takes up to 25% of the space minus some gap */
}

.filter-select {
  width: 100%;
  padding: 10px;
  background-color: var(--input-bg);
  color: var(--text-color);
  border: none;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px; /* Ensure font size is not too large */
}

/* Adjust the remote toggle to fit inline */
.remote-toggle {
  display: block;
  align-items: center;
  white-space: nowrap;
  margin-left: 8px;
}

@media (max-width: 1200px) {
  .filters {
    width: calc(100%);
    flex-wrap: wrap;
  }

  .filter-dropdown {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}

@media (max-width: 768px) {
  .filters-button {
    margin: 0px;
    padding: 10px;
    right: 0px;
    background-color: #353331;
    color: var(--text-color);
    border: none;
    font-family: 'JetBrains Mono', monospace;
    font-size: 14px;
    cursor: pointer;
  }

  .filters {
    display: none;
    flex-direction: column;
    padding: 0px;
    width: calc(100%);
    margin: 0 0px;
    box-sizing: border-box;
  }

  .filters.show {
    flex-wrap: nowrap;
    padding: 0px;
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .filter-dropdown {
    width: 100%;
    margin-bottom: 0px;
  }

  .filter-select {
    width: 100%;
    margin-bottom: 0px;
  }

  .remote-toggle {
    width: 100%;
    justify-content: flex-start;
    margin-left: 0;
    margin-top: 0px;
  }

  /* Ensure the cube doesn't overlap with filters */
  .cube-container {
    margin: 0px 0;
  }
}

.cube-container {
  width: 50px;
  height: 50px;
  right: 0px;
  z-index: 1000;
}

/* Adjust the tooltip position for desktop */
.cube-tooltip {
  position: absolute;
  right: 60px; /* Position to the left of the cube */
  top: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

/* Existing hover effect */
.cube-container:hover .cube-tooltip {
  opacity: 1;
}

/* Mobile styles */
@media (max-width: 768px) {
  .cube-container {
    width: 100%;
    height: auto;
    margin: 0 20px;
    display: flex;
    align-items: center;
  }

  .cube {
    width: 50px;
    height: 50px;
    position: relative;
    transform-origin: center center;
    left: auto;
    top: auto;
  }

  /* Add this new rule to create a fixed-size container for the cube */
  .cube-wrapper {
    width: 50px;
    height: 50px;
    position: relative;
  }
}

/* Adjust the animation to rotate around the center */
@keyframes spin {
  from { transform: rotateX(0deg) rotateY(0deg); }
  to { transform: rotateX(360deg) rotateY(360deg); }
}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: spin 10s infinite linear;
}

.cube-face {
  position: absolute;
  width: 50px;
  height: 50px;
  border: none; /* Remove the default border */
  background-image: 
    linear-gradient(to right, var(--text-color) 1px, transparent 1px),
    linear-gradient(to bottom, var(--text-color) 1px, transparent 1px);
  background-size: 10px 10px; /* Adjust this value to change dot spacing */
  background-position: -1px -1px;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: var(--text-color);
}

/* Specific transformations for each face */
.front  { transform: rotateY(0deg) translateZ(25px); }
.right  { transform: rotateY(90deg) translateZ(25px); }
.back   { transform: rotateY(180deg) translateZ(25px); }
.left   { transform: rotateY(-90deg) translateZ(25px); }
.top    { transform: rotateX(90deg) translateZ(25px); }
.bottom { transform: rotateX(-90deg) translateZ(25px); }

@keyframes spin {
  from { transform: rotateX(0deg) rotateY(0deg); }
  to { transform: rotateX(360deg) rotateY(360deg); }
}

.table-header-cell {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table-header-cell:hover {
  background-color: #292827;
}

/* If you need to adjust the opacity, you can use rgba with the variable */
/* .table-header-cell:hover {
  background-color: rgba(var(--input-bg-rgb), 0.7);
} */

/* Mobile responsiveness */
@media (max-width: 768px) {
  .job-listings {
    padding: 20px;
  }

  .header-container {
    flex-direction: column;
    align-items: stretch;
  }

  .filters {
    flex-direction: column;
    gap: 8px;
  }

  .filter-dropdown {
    width: 100%;
  }

  .filter-select {
    width: 100%;
    padding: 10px;
  }

  .remote-toggle {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .cube-container {
    margin-top: 4px;
    margin-left: -50px;
  }

  .job-content {
    overflow-x: auto;
  }

  .status-bar {
    align-items: stretch;
  }

  .status-bar-content {
    gap: 10px;
  }

  .load-more-button {
    width: 100%;
  }
}

/* Add these new rules */
.job-table {
  table-layout: fixed;
  width: 100%;
  padding-bottom: 48px;
}

.job-table th,
.job-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Adjust other column widths */
.job-table th:not(:first-child),
.job-table td:not(:first-child) {
  width: calc((100% - 460px) / 5); /* Distribute remaining width equally */
}

/* Add these styles to your existing CSS file */

.job-table-container {
  overflow-x: auto;
}

@media screen and (max-width: 768px) {
  .job-table thead {
    display: none;
  }

  .job-table, .job-table tbody, .job-table tr {
    display: block;
    width: 100%;
  }

  .job-table tr {
    border-bottom: 1px solid rgba(255, 230, 217, 0.1); /* Lighter, more visible border */
    padding: 20px 0; /* Increase padding for better spacing */
  }

  .job-table td {
    display: none; /* Hide all cells by default */
    padding: 5px 0; /* Remove horizontal padding */
    white-space: normal; /* Allow text to wrap */
    overflow: visible; /* Show overflowing content */
    text-overflow: clip; /* Don't use ellipsis */
    border-bottom: none; /* Remove any potential bottom border on cells */
  }

  /* Show and style job title */
  .job-table td:first-child {
    display: block;
    font-weight: bold;
    font-size: 16px;
    padding: 0 0 5px 0; /* Remove left padding, keep bottom padding */
    width: 100%; /* Ensure full width */
    border-bottom: none; /* Ensure no border here */
    padding-top: 0; /* Remove top padding from the first element */
  }

  /* Show and style company name */
  .job-table td:nth-child(2) {
    display: block;
    font-size: 14px;
    color: #8F827C;
    padding: 0 0 5px 0; /* Remove left padding, keep bottom padding */
    width: 100%; /* Make company name take full width */
    box-sizing: border-box; /* Include padding in width calculation */
    border-bottom: none; /* Ensure no border here */
  }

  /* Hide location */
  .job-table td:nth-child(3) {
    display: none;
  }

  /* Remove any fixed widths on table cells for mobile */
  .job-table th,
  .job-table td {
    width: auto !important;
    min-width: 0 !important;
    max-width: none !important;
  }

  /* Remove any remaining ::before content */
  .job-table td::before {
    content: none;
  }

  /* Remove hover styles for mobile */
  .job-table tbody tr:hover {
    background-color: inherit;
    cursor: default;
  }

  .job-table tbody tr:hover td,
  .job-table tbody tr:hover td a {
    color: inherit;
  }

  /* Remove the active (press) state */
  .job-table tbody tr:active {
    background-color: inherit;
  }

  /* Ensure company name color doesn't change on hover or active state */
  .job-table tbody tr:hover td:nth-child(2),
  .job-table tbody tr:active td:nth-child(2) {
    color: #8F827C;
  }

  /* Remove any remaining borders */
  .job-table,
  .job-table tbody,
  .job-table tr,


  /* Remove any remaining padding */
  .job-table th:first-child,
  .job-table td:first-child,
  .job-table th:last-child,
  .job-table td:last-child {
    padding-left: 0;
    padding-right: 0;
  }

  /* Add these new styles */
  .job-table tr:last-child {
    border-bottom: none; /* Remove border from the last row */
  }

  .job-table td:last-child {
    padding-bottom: 20px; /* Increase bottom padding for more space */
  }

  /* Add a subtle background to alternate rows for better distinction */
  .job-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.00);
  }

  /* Remove border from the last row */
  .job-table tr:last-child {
    border-bottom: none;
  }
}

/* Add these new styles for the filters button and mobile layout */
.filters-button {
  display: none;
  padding: 10px 15px;
  background-color: #353331;
  color: var(--text-color);
  border: none;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .filters-button {
    display: block;
  }

  .filters {
    display: none;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
  }

  .filters.show {
    display: flex;
  }

  .filter-dropdown {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }

  .remote-toggle {
    margin-left: 0;
    margin-top: 10px;
  }
}

.mobile-title {
  position: relative;
  top: 8px;
  left: 80px;
  font-size: 0.8rem;
  margin: 0;
  color: #FFE6D9;
  z-index: 1001;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.mobile-title.hidden {
  opacity: 0;
  transform: translateY(-20px);
}

.mobile-title-right {
  position: fixed;
  top: 32px;
  visibility: hidden;
  right: 40%;
  font-size: 0.8rem;
  margin: 0;
  color: #FFE6D9;
}

.featured-job {
  background-color: rgba(255, 230, 217, 0.1);
  position: relative;
}

.featured-job::before {
  content: 'Featured';
  position: absolute;
  left: 0;
  top: 0;
  background-color: #FFE6D9;
  color: #1E1E1E;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.featured-job:hover {
  background-color: rgba(255, 230, 217, 0.2);
}

.featured-job td:first-child {
  padding-top: 20px;
}

.job-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px); /* Adjust this value based on your header and footer height */
}

.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 18px;
}

.filter-options {
  display: flex;
  align-items: center;
  gap: 8px;
}

.featured-jobs-button {
  padding: 8px 16px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid var(--text-color);
  border-radius: 4px;
  cursor: pointer;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.featured-jobs-button.active {
  background-color: var(--text-color);
  color: var(--bg-color);
}

.featured-jobs-button:hover {
  background-color: rgba(255, 230, 217, 0.1);
}

.featured-jobs-button.active:hover {
  background-color: var(--text-color);
  opacity: 0.9;
}

/* Keep the existing remote toggle styles */
.remote-toggle {
  display: flex;
  align-items: center;
  padding-left: 0px;
  height: 42px;
  cursor: pointer;
}

/* ... rest of the existing remote toggle styles ... */

.toggle-label {
  cursor: pointer;
  user-select: none;
  padding: 5px;
}

.job-count-container {
  display: flex;
  align-items: center;
}

.loading-indicator {
  margin-left: 8px;
  margin-top: -6px;
}

.clear-filters-button {
  width: auto;  /* Allow the button to size based on content */
  min-width: 0;
  padding: 4px 12px;  /* Adjust these values to match the "Load more" button */
}

/* Add these styles to your existing CSS file */

.job-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.job-table-header,
.job-table-row {
  display: table-row;
}

.job-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
  border-bottom: 1px solid #444;
}

/* Media query for desktop viewports */
@media screen and (min-width: 768px) {
  .job-table,
  .job-table-header,
  .job-table-row {
    display: table;
    width: 100%;
  }

  .job-table-row {
    display: table-row;
  }

  .job-table-cell {
    display: table-cell;
  }
}



